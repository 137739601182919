<template>
<div :style="'--zoom: ' + zoom"
>
  <v-badge
    :key="user ? user.id : 0"
    class="avatar-badge"
    color="green"
    avatar
    bottom
    icon="mdi-feather"
    overlap
    :value="isCreator"
    offset-x="20"
    offset-y="20"
  >
    <slot />
  </v-badge>
  </div>
</template>
<script>
import hasUser from '@/mixins/hasUser';
export default {
  mixins: [hasUser],
  props: {
    user: Object,
    size: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    zoom() {
      return this.size / 50;
    },
  },
}
</script>
<style lang="scss" scoped>
  ::v-deep span.v-badge__badge {
    zoom: var(--zoom);
  }
</style>