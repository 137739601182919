import LoginModule from './login'
import RegistrationModule from './registration'

import { USER_ROLES } from "@/const"

export default {
  namespaced: true,
  modules: {
    login: LoginModule,
    registration: RegistrationModule,
  },
  state: () => ({
    me: null,
  }),
  getters: {
    getMe: (state) => {
      return state.me ? state.me : null
    },
    isCreator: (state) => {
      return state.me && state.me.roles ? state.me.roles.filter(e => e === USER_ROLES.Creator).length > 0 : false
    },
    isAdmin: (state) => {
      return state.me && state.me.roles ? state.me.roles.filter(e => e === USER_ROLES.Admin).length > 0 : false
    },
    roles: (state) => state.me ? state.me.roles : null
  },
  mutations: {
    setMe(state, me) {
      state.me = me
    },
  },
}