<template>
  <div>
    <v-progress-circular
      v-if="$apollo.queries.user.loading"
      color="primary"
      indeterminate
    />
    <v-btn v-else-if="!user" color="primary" class="mx-10" to="/auth/sign-in">
      {{ $t("login.loginNoun") }}
    </v-btn>
    <v-menu
      v-else-if="user"
      content-class="story-city-tooltip rounded-lg"
      bottom
      center
      offset-y
      elevation="0"
    >
      <template v-slot:activator="{ on }">
        <RoleBadge :user="user">
          <v-btn icon v-on="on">
            <Avatar :userProfile="user.userProfile" />
          </v-btn>
        </RoleBadge>
      </template>
      <v-card v-if="user" color="secondaryBackground" class="pa-3" width="250px">
        <div class="align-center justify-center text-center">
          <AvatarWithBadge :user="user" />
          <h3 v-if="user.userProfile">
            {{
              $t("fullName", {
                firstName: user.userProfile.firstName,
                lastName: user.userProfile.lastName,
              })
            }}
          </h3>
          <p class="text-caption mt-1">
            {{ user.email }}
          </p>
          <v-btn
            class="rounded-lg textOnPrimary--text"
            color="primaryLight"
            depressed
            block
            :to="{ name: 'user-settings' }"
          >
            <v-icon class="mr-2">mdi-cog</v-icon>
            {{ $t("userSettings.userSettings") }}
          </v-btn>
          <v-btn
            v-if="user.userProfile && !user.isVerified"
            class="rounded-lg textOnPrimary--text mt-3"
            color="secondaryLight"
            depressed
            block
            :to="{ name: 'verify-email' }"
          >
            {{ $t("register.verification.verifyAccount") }}
          </v-btn>
          <v-divider class="my-3"></v-divider>
          <v-btn
            class="rounded-lg"
            block
            depressed
            rounded
            text
            @click="logout"
          >
            {{ $t("login.logOutVerb") }}
          </v-btn>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import hasLoggedInUser from "@/mixins/hasLoggedInUser";
import Avatar from "@/components/User/Avatar";
import AvatarWithBadge from "@/components/User/AvatarWithBadge";
import RoleBadge from "@/components/User/RoleBadge";
import logout from "@/logout";
export default {
  mixins: [hasLoggedInUser],
  components: {
    Avatar,
    AvatarWithBadge,
    RoleBadge,
  },
  methods: {
    logout() {
      logout();
    },
  },
};
</script>
