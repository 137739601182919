<template>
  <v-dialog
    v-model="dialog"
    class="dialog story-city-dialog-card"
    content-class="story-city-dialog"
    :max-width="defaultDialogWidth"
    elevation="0"
  >
    <template v-slot:activator="{ on, attrs }">
      <StoryCityGradientButton
        x-large
        class="story-city-button button-box-shadow pa-1 pr-8"
        v-bind="attrs"
        :on="on"
      >
        <div class="d-flex align-center">
          <v-icon size="45" class="mr-3">
            $add
          </v-icon>
          <div class="create-button__text">
            {{ $t("story.create.createAStory") }}
          </div>
        </div>
      </StoryCityGradientButton>
    </template>
    <v-card color="secondaryBackground" class="dialog-card px-10 py-12">
      <v-btn
        absolute
        class="close-dialog-button"
        icon
        @click="dialog = false"
        color="tertiaryText"
      >
        <v-icon>$close</v-icon>
      </v-btn>
      <v-container class="pa-0">
        <h2 class="dialog__title mb-6">
          {{ $t("story.create.createANewStory") }}
        </h2>
        <div class="d-flex justify-space-between mb-3">
          <h4 class="dialog__subtitle">
            {{ $t("story.create.keepingItBriefWhatsYourStoryAbout?") }}
          </h4>
          <v-tooltip
            content-class="story-city-tooltip"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <img
                v-bind="attrs"
                v-on="on"
                :src="require('@/assets/icons/question_mark.svg')"
              />
            </template>
            <span v-html="$t('story.create.aShortDescriptionYouCanEdit')">
            </span>
          </v-tooltip>
        </div>
        <Tagline v-model="summary" hide-label class="mb-5" />
        <SelectTemplate
          class="mb-4"
          @template-selected="selectedTemplate = $event"
        />
        <ApolloMutation
          :mutation="(gql) => createStoryQuery"
          :variables="{
            summary: summary,
            templateId: selectedTemplate,
          }"
          :refetchQueries="refetchQueries"
          @done="onDone"
        >
          <template v-slot="{ mutate, loading, error }">
            <StoryCityGradientButton
              block
              x-large
              :loading="loading"
              @click="
                mutate({
                  refetchQueries: ['GetMyStories'],
                })
              "
              :disabled="!buttonActive || loading"
            >
              {{ $t("story.create.createYourStory") }}
            </StoryCityGradientButton>
            <div v-if="error">
              {{ error }}
            </div>
          </template>
        </ApolloMutation>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Tagline from "@/components/CreatorTools/StoryHub/BasicInfo/Tagline";
import StoryMutations from "@/graphql/StoryMutations";
import SelectTemplate from "./SelectTemplate.vue";
import StoryCityGradientButton from "@/components/Buttons/StoryCityGradientButton.vue";
import { DEFAULT_DIALOG_WIDTH } from "@/const";

export default {
  components: {
    Tagline,
    SelectTemplate,
    StoryCityGradientButton,
  },
  data() {
    return {
      dialog: false,
      summary: "",
      storyId: null,
      cacheUpdated: false,
      selectedTemplate: null,
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.summary = "";
      }
    },
  },
  computed: {
    createStoryQuery() {
      return StoryMutations.createStory;
    },
    getMyStoriesQuery() {
      return StoryMutations.getMyStories;
    },
    buttonActive() {
      return this.summary && this.summary != "" ? true : false;
    },
    defaultDialogWidth() {
      return DEFAULT_DIALOG_WIDTH;
    },
  },
  methods: {
    refetchQueries() {
      return [
        {
          query: StoryMutations.getMyStories,
        },
      ];
    },
    onDone(response) {
      this.storyId = response.data.createStory.story._id;
      this.dialog = false;
      this.summary = "";

      this.$router.push("/storyHub/" + this.storyId + "/basic-info");
    },
  },
};
</script>

<style lang="scss" scoped>
.create-button__text {
  color: var(--v-textOnPrimary-base);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
