import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { icons } from '@/plugins/icons.js';
import { light } from '@/plugins/themes.js';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light
    },
  },
  icons,
});
