export default {
  state: {
    inProgress: false,
    success: false,
    errorMessages: [],
    showUnsavedChangesModal: false,
  },
  getters: {
    getInProgress: (state) => state.inProgress,
    getSuccess: (state) => state.success,
    getErrorMessages: (state) => state.errorMessages,
    getShowUnsavedChangesModal: (state) => state.showUnsavedChangesModal,
  },
  mutations: {
    setInProgress: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.inProgress = value
    },
    setSuccess: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.success = value
    },
    setShowUnsavedChangesModal: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.showUnsavedChangesModal = value
    },
    /* eslint-disable no-param-reassign */
    succeeded: (state) => {
      state.success = true
      state.inProgress = false
    },
    /* eslint-enable no-param-reassign */
    /* eslint-disable no-param-reassign */
    failed: (state) => {
      state.success = false
      state.inProgress = false
      state.errorMessages = []
    },
    /* eslint-enable no-param-reassign */
    setErrorMessages: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.errorMessages = value
    },
    addErrorMessage: (state, value) => {
      state.errorMessages.push(value)
    },
    /* eslint-disable no-param-reassign */
    clear: (state) => {
      state.inProgress = false
      state.success = false
      state.errorMessages = []
    },
    /* eslint-enable no-param-reassign */
    /* eslint-disable no-param-reassign */
    start: (state) => {
      state.inProgress = true
      state.success = false
      state.errorMessages = []
    },
    /* eslint-enable no-param-reassign */
  },
}