<template>
  <v-btn
    v-bind="$attrs"
    fab
    elevation="0"
    class="storycity-icon-btn"
    @click="$emit('click')"
  >
    <slot/>
  </v-btn>
</template>

<script>
export default {
  name: "StoryCityIconBtn",
}
</script>

<style lang="scss" scoped>
.storycity-icon-btn {
  border-radius: 12px;
  width: 62px;
  height: 62px;
}
</style>