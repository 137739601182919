
export default {
  namespaced: true,
  state: {
    text: '',
  },
  getters: {
    getText: (state) => state.text
  },
  mutations: {
    setText(state, payload) {
      state.text = payload
    }
  },
}