import gql from 'graphql-tag'

export const chapterFragments = {
  chapter: gql`
    fragment chapter on Chapter {
      id
      text
      name
      orderInStory
      createdAt
      updatedAt
      hideMap
      disableLocation
      isStartChapter
      inAppChapterName
      hideChapterName
      endOfChapterLabel
      hideEndOfChapterLabel
      featuredMediaObject {
        id
        contentUrl
      }
      place {
        id
        name
        latitude
        longitude
        radius
      }
      story {
        id
      }
      isStartChapter
      isEndChapter
      chapterConnectors {
        id
        name
        toChapter {
          id
          name
        }
      }
      chapterConnectorsTo {
        id
        name
        fromChapter {
          id
          name
        }
      }
      universalFocusName
      universalFocusDescription
    }
  `,
  newPlace: gql`
  fragment NewPlace on Place {
    id
    name
    latitude
    longitude
    radius
  }
  `,
  newChapter: gql`
    fragment NewChapter on Chapter {
      id
    }
  `,
  newChapterConnector: gql`
    fragment NewChapterConnector on ChapterConnector {
      id
      name
      toChapter {
        id
        name
      }
      fromChapter {
        id
        name
      }
    }
  `,
}

export const chapterQueries = {
  createChapter: gql`mutation CreateChapter(
    $story: String!
    $name: String!
  ){
    createChapter(input:{
      story: $story
      name: $name
      isEndChapter: false
      inAppChapterName: ""
      hideChapterName: false
      endOfChapterLabel: ""
      hideEndOfChapterLabel: false
    }) {
      chapter {
        ...chapter
      }
    }
  }
  ${chapterFragments.chapter}
  `,
  getChapters: gql`query GetChapters($storyId: String) {
    chapters(story: $storyId) {
      ...chapter
    }
  }
  ${chapterFragments.chapter}
  `,
  updateChapterName: gql`mutation UpdateChapterName($chapterId: ID!, $name: String) {
    updateChapter(input: { id: $chapterId, name: $name }) {
      chapter {
        ...chapter
      }
    }
  }
  ${chapterFragments.chapter}
  `,
  updateUniversalFocusName: gql`mutation UpdateUniversalFocusName($chapterId: ID!, $universalFocusName: String) {
    updateChapter(input: { id: $chapterId, universalFocusName: $universalFocusName }) {
      chapter {
        ...chapter
      }
    }
  }
  ${chapterFragments.chapter}
  `,
  updateUniversalFocusDescription: gql`mutation UpdateUniversalFocusDescription($chapterId: ID!, $universalFocusDescription: String) {
    updateChapter(input: { id: $chapterId, universalFocusDescription: $universalFocusDescription }) {
      chapter {
        ...chapter
      }
    }
  }
  ${chapterFragments.chapter}
  `,
  updateChapterText: gql`mutation UpdateChapterName($chapterId: ID!, $text: String) {
    updateChapter(input: { id: $chapterId, text: $text }) {
      chapter {
        ...chapter
      }
    }
  }
  ${chapterFragments.chapter}
  `,
  updateChapterHideMap: gql`mutation UpdateChapterHideMap($chapterId: ID!, $hideMap: Boolean) {
    updateChapter(input: { id: $chapterId, hideMap: $hideMap }) {
      chapter {
        ...chapter
      }
    }
  }
  ${chapterFragments.chapter}
  `,
  updateChapterDisableLocation: gql`mutation UpdateChapterDisableLocation($chapterId: ID!, $disableLocation: Boolean) {
    updateChapter(input: { id: $chapterId, disableLocation: $disableLocation }) {
      chapter {
        ...chapter
      }
    }
  }
  ${chapterFragments.chapter}
  `,
  updateChapterConnector: gql` mutation UpdateChapterConnector($chapterConnectorId: ID!, $toChapterId: String) {
    updateChapterConnector(input: {id: $chapterConnectorId, toChapter: $toChapterId} ) {
      chapterConnector {
        id
        toChapter {
          id
          name
        }
      }
    }
  }`,
  updateChapterConnectorName: gql` mutation UpdateChapterConnector($chapterConnectorId: ID!, $name: String) {
    updateChapterConnector(input: {id: $chapterConnectorId, name: $name} ) {
      chapterConnector {
        id
        name
        toChapter {
          id
          name
        }
      }
    }
  }`,
  getChapterWithFeaturedMediaObject: gql`query GetChapterWithFeaturedMediaObject($id: ID!) {
    chapter(id: $id) {
      id
      featuredMediaObject {
        id
        contentUrl
        mimeType
      }
    }
  }`,
  createChapterConnector: gql` mutation CreateChapterConnector($fromChapterId: String) {
    createChapterConnector(input: {fromChapter: $fromChapterId}) {
      chapterConnector {
        ...NewChapterConnector
      }
    }
  }
  ${chapterFragments.newChapterConnector}
  `,
  deleteChapterConnector: gql` mutation DeleteChapterConnector($chapterConnectorId: ID!) {
    deleteChapterConnector(input: {id: $chapterConnectorId}) {
      chapterConnector {
        id
      }
    }
  }`,
  deleteChapter: gql` mutation DeleteChapter($chapterId: ID!) {
    deleteChapter(input: {id: $chapterId}) {
      chapter {
        id
      }
    }
  }`,
  archiveChapter: gql` mutation ArchiveChapter($chapterId: ID!) {
    archiveChapter(input: {id: $chapterId}) {
      chapter {
        id
      }
    }
  }`,
  deleteFeaturedContent: gql`mutation DeleteFeaturedContent($id: ID!) {
    deleteFeaturedMediaObjectChapter(input: {id: $id}) {
      chapter {
        id
        featuredMediaObject {
          id
        }
      }
    }
  }
  `,
  removeChapterPlace: gql`
    mutation RemoveChapterPlace($chapterId: ID!) {
      removePlaceChapter(input: {id: $chapterId}) {
        chapter {
          id
          place {
            id
          }
        }
      }
    }
  `,
  moveChapter: gql` mutation MoveChapter(
      $chapterId: ID!, $newPosition: Int!
    ) {
    moveChapter(input: {id: $chapterId, newPosition: $newPosition}) {
      chapter {
        id
        story {
          id 
          chapters {
            id
            orderInStory
          }
        }
      }
    }
  }`,
  updateChapterPlace: gql` mutation updateChapterPlace(
    $chapterId: ID!,
    $latitude: Float!,
    $longitude: Float!,
    $name: String,
    $radius: Int!) {
    createOrUpdatePlaceChapter(
      input: {
        id: $chapterId
        latitude: $latitude
        longitude: $longitude
        radius: $radius
        placeName: $name
      }) {
        chapter {
          id
          place {
            id
            latitude
            longitude
            radius
            name
          }
        }
    }
  }`,
  createPlace: gql`mutation createPlace(
    $latitude: float!,
    $longitude: float!,
    $radius: int!) {
    createPlace(
      input: {
        latitude: $latitude,
        longitude: $longitude,
        radius: $radius
      }) {
      place {
        ...newPlace
      }
    }
  }
  ${chapterFragments.newPlace}
  `,
  getChapterConnectorRequirements: gql`
    query GetChapterConnectorRequirements($connectionId: ID!) {
      chapterConnector(id: $connectionId) {
        id
        name
        chapterRequirements {
          id
          _id
          matchContent
          type {
            id
            _id
          }
        }
      }
    }
  `,
  getRequirementTypes: gql`
    query GetRequirementTypes {
      chapterRequirementTypes {
        id
        _id
      }
    }
  `,
  createChapterRequirement: gql`
    mutation CreateChapterRequirement($content: String!, $connectionId: String!, $typeId: String!) {
      createChapterRequirement(input: {
        matchContent: $content,
        chapterConnector: $connectionId,
        type: $typeId
      }) {
        chapterRequirement {
          id
          _id
          matchContent
          chapterConnector {
            id
            name
          }
          type {
            id
            _id
          }
        }
      }
    }
  `,
  updateChapterRequirement: gql`
    mutation updateChapterRequirement($input: updateChapterRequirementInput!) {
      updateChapterRequirement(input: $input) {
        chapterRequirement {
          id
          matchContent
          chapterConnector {
            id
            name
          }
          type {
            id
            _id
          }
        }
      }
    }
  `,
  deleteChapterRequirement: gql`
    mutation DeleteChapterRequirement($id: ID!) {
      deleteChapterRequirement(input: {
        id: $id
      }) {
        chapterRequirement {
          id
          _id
        }
      }
    }
  `,
}