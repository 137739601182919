import { USER } from "@/graphql/AuthMutations";
import { mapGetters, mapMutations } from "vuex";
import hasUser from "@/mixins/hasUser";
export default {
  mixins: [hasUser],
  apollo: {
    user: {
      query: USER,
      update(data) {
        return data.getLoggedInUser;
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["getMe"]),
    userId() {
      return this.user.id
    },
  },
  methods: {
    ...mapMutations("auth", ["setMe"]),
  },
  watch: {
    getMe() {
      this.$apollo.queries.user.refetch()
    }
  },
}