import formSubmit from '@/store/modules/formSubmit'
import { REGISTER_USER } from '@/graphql/AuthMutations'
import { AUTH_TOKEN, REFRESH_TOKEN } from '@/apollo/vue-apollo'
import { apolloClient } from '@/apollo/vue-apollo'

export const socialTokenType = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  APPLE: 'apple',
}

export default {
  namespaced: true,
  state: {
    ...formSubmit.state,
    profileImageId: null,
    username: null,
    email: null,
    password: null,
    socialToken: null,
    socialTokenType: null,
  },
  getters: {
    ...formSubmit.getters,
    profileImageId: (state) => state.profileImageId,
    username: (state) => state.username,
    email: (state) => state.email,
    password: (state) => state.password,
    socialToken: (state) => state.socialToken,
    socialTokenType: (state) => state.socialTokenType,
  },
  mutations: {
    ...formSubmit.mutations,
    setProfileImageId(state, value) { state.profileImageId = value },
    setUsername(state, value) { state.username = value },
    setEmail(state, value) { state.email = value },
    setPassword(state, value) { state.password = value },
    setSocialToken(state, value) { state.socialToken = value },
    setSocialTokenType(state, value) { 
      if (Object.values(socialTokenType).includes(value) || value == null) {
        state.socialTokenType = value
      } else {
        state.socialTokenType = null
        console.error('Invalid social token type')
      }
    },
  },
  actions: {
    async register({ commit, getters }) {
      commit('start')
      let variables = {
            email: undefined,
            password: undefined,
            username: getters.username,
            mediaObjectId: getters.profileImageId,
      };
      if (getters.socialToken) {
        if (getters.socialTokenType == socialTokenType.GOOGLE) {
          variables.googleToken = getters.socialToken
        } else if (getters.socialTokenType == socialTokenType.FACEBOOK) {
          variables.facebookToken = getters.socialToken
        } else if (getters.socialTokenType == socialTokenType.APPLE) {
          variables.appleToken = getters.socialToken
        }
      } else {
        variables.email = getters.email
        variables.password = getters.password
      }

      try {
        let response = await apolloClient.mutate({
          mutation: REGISTER_USER,
          variables: variables,
        })
        let user = response.data.registerUser.user
        localStorage.setItem(AUTH_TOKEN, user.token)
        localStorage.setItem(REFRESH_TOKEN, user.refreshToken)
        let me = user
        me.token = null
        me.refreshToken = null
        commit('auth/setMe', me, { root: true })
        commit('succeeded')

      } catch (error) {
        commit('failed')
        for (const graphQlError of error.graphQLErrors) {
          if (graphQlError.debugMessage) {
            commit('addErrorMessage', graphQlError.debugMessage)
          }
        }
      }
    },
    clearSocialToken({ commit }) {
      commit('setSocialToken', null)
      commit('setSocialTokenType', null)
    },
  },
}