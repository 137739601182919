<template>
  <svg
    class="custom-icon"
    viewBox="424.36 124.305 22 22"
    width="22"
    height="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="information (2) 1"
      transform="matrix(1, 0, 0, 1, 422.287506, 131.010498)"
    >
      <path
        id="Vector"
        d="M 3.072 4.294 C 3.072 -1.229 7.549 -5.706 13.072 -5.706 C 18.595 -5.706 23.073 -1.229 23.073 4.294 C 23.073 9.817 18.595 14.295 13.072 14.295 C 7.549 14.295 3.072 9.817 3.072 4.294 Z M 14.78 9.882 C 14.967 9.695 15.073 9.44 15.073 9.175 C 15.073 8.91 14.967 8.655 14.78 8.468 C 14.592 8.28 14.338 8.175 14.073 8.175 L 14.073 3.175 C 14.073 2.91 13.967 2.655 13.78 2.468 C 13.592 2.28 13.338 2.175 13.073 2.175 L 12.073 2.175 C 11.807 2.175 11.553 2.28 11.365 2.468 C 11.178 2.655 11.072 2.91 11.072 3.175 C 11.072 3.44 11.178 3.695 11.365 3.882 C 11.553 4.07 11.807 4.175 12.073 4.175 L 12.073 8.175 C 11.807 8.175 11.553 8.28 11.365 8.468 C 11.178 8.655 11.072 8.91 11.072 9.175 C 11.072 9.44 11.178 9.695 11.365 9.882 C 11.553 10.07 11.807 10.175 12.073 10.175 L 14.073 10.175 C 14.338 10.175 14.592 10.07 14.78 9.882 Z M 13.073 0.925 C 13.763 0.925 14.323 0.365 14.323 -0.325 C 14.323 -1.015 13.763 -1.575 13.073 -1.575 C 12.382 -1.575 11.822 -1.015 11.822 -0.325 C 11.822 0.365 12.382 0.925 13.073 0.925 Z"
        fill="#4CB6F6"
        style=""
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Info",
};
</script>
