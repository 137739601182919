import { apolloProvider } from '@/main'
import { onLogout } from '@/apollo/vue-apollo'
import { AUTH_TOKEN, REFRESH_TOKEN } from "@/apollo/vue-apollo"
import store from '@/store/index'

import router from '@/router'

export default async () => {
    localStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN);
    store.commit('auth/setMe', null);
    onLogout(apolloProvider.defaultClient)
    router.push("/auth/sign-in");
}