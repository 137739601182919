var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BasicInfoLabel',{attrs:{"imageLocation":require('@/assets/icons/page.svg'),"showWarning":_vm.showWarning}},[_vm._v(" "+_vm._s(_vm.$t("story.tagline"))+" ")]),_c('v-textarea',_vm._b({staticClass:"story-city-textarea pa-0 mb-0",attrs:{"id":"taglineTextArea","label":_vm.$t('story.basicInfoForm.addYourTagline'),"single-line":"","auto-grow":"","rules":[
      () =>
        _vm.summary == null ||
        _vm.summary.length <= _vm.TAGLINE_TEXT_MAX_LENGTH ||
        _vm.$t('forms.maxLimitCharacters', {
          limit: _vm.TAGLINE_TEXT_MAX_LENGTH,
        }),
    ],"hide-details":"auto","solo":"","outlined":"","flat":"","disabled":_vm.disabled},model:{value:(_vm.summary),callback:function ($$v) {_vm.summary=$$v},expression:"summary"}},'v-textarea',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }