import gql from 'graphql-tag'

const REFRESH_USER_TOKEN = gql` mutation($refreshToken: String!) {
    refreshTokenUser(input: {refreshToken: $refreshToken}) {
      user {
        token
      }
    }
  }`

const loginFragments = {
  userInfo: gql`
    fragment userInfo on User {
      id
      token
      refreshToken
      userProfile {
        id
        firstName
        lastName
        username
        image {
          contentUrl
        }
      }
      roles     
    }`,
}

const APPLE_LOGIN = gql`mutation Login($appleToken: String!) {
  appleLoginUser(input: {appleToken: $appleToken, mobile: false}) {
    user { 
      ...userInfo
    }
  }
}
${loginFragments.userInfo}
`

const FACEBOOK_LOGIN = gql`mutation Login($facebookToken: String!) {
  facebookLoginUser(input: {facebookToken: $facebookToken}) {
    user { 
      ...userInfo
    }
  }
}
${loginFragments.userInfo}
`

const GOOGLE_LOGIN = gql`mutation Login($googleToken: String!) {
  googleLoginUser(input: {googleToken: $googleToken})  {
    user { 
      ...userInfo
    }
  }
}
${loginFragments.userInfo}
`

const LOGIN = gql`mutation Login($email: String!, $password: String!) {
  loginUser(input: {email: $email, password: $password})  {
    user { 
      ...userInfo
      email
    }
  }
}
${loginFragments.userInfo}
`

const RESET_PASSWORD = gql`mutation ResetPassword($token: String!, $password: String!) {
  resetPasswordUser(input: {token: $token, password: $password } ) {
    user {
      email
      id
      token
      refreshToken
    }
  }
}
`
const REQUEST_RESET_PASSWORD = gql`mutation RequestResetPassword($email: String!) {
  requestResetPasswordUser(input: { email: $email } ) {
    user {
      id
    }
  }
}
`

const REGISTER_USER = gql`mutation RegisterUser(
  $email: String
  $password: String
  $username: String!
  $mediaObjectId: String
  $facebookToken: String
  $googleToken: String
  $appleToken: String
  ) {
  registerUser(input: {
    email: $email
    password: $password
    username: $username
    mediaObjectId: $mediaObjectId
    googleToken: $googleToken
    facebookToken: $facebookToken
    appleToken: $appleToken
  } ) {
    user {
      ...userInfo
      email
    }
  }
}
${loginFragments.userInfo}
`

const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    handleDeleteUser(
      input: { id: $id }
    ) {
      user {
        id
      }
    }
  }
`;

const USER = gql`query GetUser {
  getLoggedInUser {
      id
      email
      roles
      isVerified
      stripeAccount {
        id
        chargesEnabled
      }
      userProfile {
        id
        firstName
        lastName
        username
        penName
        image {
          id
          contentUrl
        }
      }
    }
}`

export {
  REFRESH_USER_TOKEN,
  LOGIN,
  GOOGLE_LOGIN,
  FACEBOOK_LOGIN,
  APPLE_LOGIN,
  USER,
  RESET_PASSWORD,
  REQUEST_RESET_PASSWORD,
  REGISTER_USER,
  DELETE_USER,
}