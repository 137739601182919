import { USER_ROLES } from "@/const";
export default {
  computed: {
    userId() {
      return this.user.id
    },
    isAdmin() {
      return this.hasRole(USER_ROLES.Admin)
    },
    isCreator() {
      return this.hasRole(USER_ROLES.Creator)
    },
  },
  methods: {
    hasRole(roleToCheck) {
      if (!this.user) {
        return false;
      }
      for (const role of this.user.roles) {
        if (role == roleToCheck) {
          return true;
        }
      }
      return false
    },
  },
}
