import gql from 'graphql-tag'
import { chapterFragments } from './ChapterQueries'

export default {
  updateStory: gql` mutation(
    $id: ID!,
    $name: String,
    $summary: String,
    $description: String,
    $genre: String,
    $ageRating: String,
    $duration: String,
    $tags: [String],
    $isTemplate: Boolean,
    $isUniversal: Boolean,
    $templateDescription: String,
    $templateIcon: String,
    $templatePreviewLink: String,
  ){
    updateStory(input:{
      id: $id,
      name: $name,
      summary: $summary,
      description: $description,
      genre: $genre,
      ageRating: $ageRating,
      tags: $tags,
      duration: $duration,
      isTemplate: $isTemplate,
      isUniversal: $isUniversal,
      templateDescription: $templateDescription,
      templateIcon: $templateIcon,
      templatePreviewLink: $templatePreviewLink,
    }) {
      story {
        id
        name
        summary
        duration
        description
        isTemplate
        isUniversal
        templateDescription
        templateIcon
        templatePreviewLink
        genre {
          id
        }
        ageRating {
          id
        }
        tags {
          id
        }
      }
    }
  }
  `,
  publishAttemptStory: gql` mutation($id: ID!) {
    publishAttemptStory(input: { id: $id }) {
      story {
        id
        publishAttemptTime
      }
    }
  }`,
  updateStoryStatus: gql` mutation($id: ID!, $status: String) {
    updateStory(input: { id: $id, status: $status }) {
      story {
        id
        status {
          id
        }
        statusUpdatedAt
      }
    }
  }`,
  updateStoryPreviewEnabled: gql` mutation($id: ID!, $previewEnabled: Boolean) {
    updateStory(input: { id: $id, previewEnabled: $previewEnabled }) {
      story {
        id
        previewEnabled
      }
    }
  }`,
  updateStoryOwner: gql` mutation($id: ID!, $owner: String!) {
    updateOwnerStory(input: { id: $id, newOwner: $owner }) {
      story {
        id
        owner {
          id
          email
          userProfile {
            id
            username
          }
        }
      }
    }
  }`,
  getStoryMetaData: gql` query GetStoryMetadData($storyId: ID!) {
    story(id: $storyId) {
      id
      name
      summary
      description
      socialLink {
        image {
          contentUrl
        }
      }
    }
  }`,
  getStoryWithChapters: gql` query GetStoryWithChapters($storyId: ID!) {
    story(id: $storyId) {
      id
      name
      isUniversal
      publishAttemptTime
      owner {
        id
      }
      chapters {
        ...chapter
      }
    }
  }
  ${chapterFragments.chapter}
  `,
  getStoryContributions: gql`query GetStoryContributions($storyId: ID!) {
    story(id: $storyId) {
      id
      name
      storyContributions {
        id
        contributor {
          id
          userProfile {
            id
            username
            firstName
            lastName
            image {
              contentUrl
            }
          }
        }
        contributorWebsite
        role {
          id
          _id
        }
      }
    }
  }
  `,
  deleteStoryContribution: gql`mutation DeleteStoryContribution($id: ID!) {
    deleteStoryContribution(input: {id: $id}) {
      storyContribution {
        id
      }
    }
  }`,
  createStory: gql`mutation CreateStory($summary: String, $templateId: String) {
    createStory(input: {summary: $summary, templateId: $templateId}) {
      story {
        id
        _id
        summary
      }
    }
  }
  `,
  getMyStories: gql`
        query GetMyStories($userId: String) {
          stories(first: 1000, owner_id: $userId) {
            totalCount
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              cursor
              node {
                coverArt {
                  contentUrl
                }
                reviews {
                  totalCount
                  edges {
                    node {
                      rating
                    }
                  }
                }
                startChapter {
                  id
                  place {
                    id
                    name
                  }
                }
                duration
                name
                summary
                genre {
                  _id
                }
                ageRating {
                  _id
                }
                status {
                  _id
                }
                tags {
                  _id
                }
                id
                _id
                statusUpdatedAt
              }
            }
          }
        }
      `,
}
