import "regenerator-runtime";
import '@/sass/app.scss';
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import { createProvider } from './apollo/vue-apollo'
import store from './store';

import VueMask from 'v-mask'
import i18n from './i18n'
Vue.use(VueMask);

Vue.config.productionTip = false

export const apolloProvider = createProvider()

new Vue({
  vuetify,
  router,
  store,
  apolloProvider: apolloProvider,
  i18n,
  render: h => h(App)
}).$mount('#app')
