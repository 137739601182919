import { ApolloClient, createHttpLink, fromPromise } from '@apollo/client/core'

import { onError } from '@apollo/client/link/error'
import logout from '@/logout'

import { AUTH_TOKEN, REFRESH_TOKEN } from "@/apollo/vue-apollo"
import { REFRESH_USER_TOKEN } from '@/graphql/AuthMutations'

import cache from './cache'
import store from '../store'

// FROM: https://stackoverflow.com/questions/50965347/how-to-execute-an-async-fetch-request-and-then-retry-last-failed-request/51321068#51321068
let isRefreshing = false
let pendingRequests = []

const setIsRefreshing = (value) => {
  isRefreshing = value
}

const addPendingRequest = (pendingRequest) => {
  pendingRequests.push(pendingRequest)
}

const renewTokenApiClient = new ApolloClient({
  link: createHttpLink({ uri: process.env.VUE_APP_GRAPHQL_HTTP }),
  cache,
  credentials: 'include',
})

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

const getNewToken = async () => {
  const oldRenewalToken = localStorage.getItem(REFRESH_TOKEN)

  try {
  let response = await renewTokenApiClient.mutate({
    mutation: REFRESH_USER_TOKEN,
    variables: { refreshToken: oldRenewalToken },
  })
  let newToken = response.data.refreshTokenUser.user.token

  if (newToken) {
    localStorage.setItem(AUTH_TOKEN, newToken)
  }
  } catch (error) {
    logout()
  }
}

const errorLink = onError(({ networkError, operation, forward, response }) => {

  if (response && response.errors) {
    for (let error of response.errors) {
      if (error.debugMessage || error.name) {
        store.commit('serverError/setErrorCode', error?.name);
        store.commit('serverError/setErrorMessage', error?.debugMessage);
      } else if (error.extensions.category == "graphql") {
        store.commit('serverError/setErrorCode', "GraphQlError");
        store.commit('serverError/setErrorMessage', error.message);
      }
      break;
    }
  }

  if (networkError && networkError.message.includes("Failed to fetch")) {
    store.commit('serverError/setErrorCode', "ServerUnavailable");
    return null;
  }

  if (networkError && networkError.statusCode == 401 && (networkError.result.message == "Invalid JWT Token" || networkError.result.message == "Expired JWT Token" || networkError.result.message == "Invalid credentials.")) {
    if (!isRefreshing) {
      setIsRefreshing(true)

      return fromPromise(
        getNewToken().catch(() => {
          resolvePendingRequests()
          setIsRefreshing(false)

          localStorage.clear()

          return forward(operation)
        }),
      ).flatMap(() => {
        resolvePendingRequests()
        setIsRefreshing(false)

        return forward(operation)
      })
    } else {
      return fromPromise(
        new Promise((resolve) => {
          addPendingRequest(() => resolve())
        }),
      ).flatMap(() => {
        return forward(operation)
      })
    }
  }
  else {
    return null
  }
})

export default errorLink