export const MAX_PASSWORD_LENGTH = 64
export const MIN_PASSWORD_LENGTH = 8

export const DEFAULT_MAP_ZOOM = 15

export const MAX_IMAGE_UPLOAD_MEGABYTES = 5
export const MAX_IMAGE_UPLOAD_BYTES = MAX_IMAGE_UPLOAD_MEGABYTES * 1024 * 1024

export const MIN_IMAGE_WIDTH = 300
export const MIN_IMAGE_HEIGHT = 300

export const MAX_VIDEO_UPLOAD_MEGABYTES = 250 
export const MAX_VIDEO_UPLOAD_BYTES = MAX_VIDEO_UPLOAD_MEGABYTES * 1024 * 1024

export const MAX_AUDIO_UPLOAD_MEGABYTES = 50 
export const MAX_AUDIO_UPLOAD_BYTES = MAX_AUDIO_UPLOAD_MEGABYTES * 1024 * 1024

export const IMAGE_FORMATS = "image/png, image/jpeg, image/bmp, image/gif"
export const AUDIO_FORMATS = "audio/*"
export const VIDEO_FORMATS = "video/mp4,video/x-m4v,video/*,video/mkv,.mkv"

export const MAX_CHAPTER_NAME_CHARS = 60;

export const USER_ROLES = Object.freeze({
  Sponsor: "ROLE_SPONSOR",
  Admin: "ROLE_ADMIN",
  Creator: "ROLE_CREATOR",
})

export const MAX_USERNAME_CHARS = 24;
export const MIN_USERNAME_CHARS = 4;

export const MAX_CONTRIBUTOR_WEBSITE_LENGTH = 254;

export const DEFAULT_DEBOUNCE_DURATION_MILLISECONDS = 500

export const STORY_STATUS = Object.freeze({
  InReview: "In Review",
  Published: "Published",
  Draft: "Draft",
  Archived: "Archived",
})

export const GOOGLE_PLAY_STORY_CITY_URL = "https://play.google.com/store/apps/details?id=au.com.storycity.storycity" 
export const IOS_STORE_STORY_CITY_URL = "https://apps.apple.com/gb/app/story-city/id1605647002" 
export const DESKTOP_DOWNLOAD_STORY_CITY_URL = "https://about.storycity.app/about/download-story-city-today/";
export const APP_UNIVERSAL_LINK = "storycity://";
export const CLOSEST_STORY_PATH = "closest-story";
export const STORY_PATH = "story/";
export const GOOGLE_GEOCODE_API_BASE_URL = "https://maps.googleapis.com/maps/api/geocode/json";

export const USER_STORY_STATUS = Object.freeze({
  InLibrary: 1,
  Purchased: 2,
  Unowned: 0,
})

export const GRADIENTS = Object.freeze({
  primary: "linear-gradient(64deg, var(--v-primary-base) 16.45%, var(--v-primaryDark-base) 83.55%)",
  primaryReverse: "linear-gradient(64deg, var(--v-primaryDark-base) 16.45%, var(--v-primary-base) 83.55%)",
  secondary: "linear-gradient(64deg, var(--v-secondary-base) 16.45%, var(--v-redDark-base) 83.55%)",
  secondaryReverse: "linear-gradient(64deg, var(--v-redDark-base) 16.45%, var(--v-secondary-base) 83.55%)",
  backgroundAccent: "linear-gradient(180deg, var(--v-secondaryBackground-base) 0%, #F3F9FF 100%)"
});

export const DEFAULT_APP_BAR_HEIGHT = '88px';
export const DEFAULT_ICON_SIZE = '18px';
export const DEFAULT_DIALOG_WIDTH = '612px';

export const DEFAULT_SUGGESTED_PRICE_CAD = 3.99;

export const USER_AGREEMENT_LINK = "https://about.storycity.app/creator-agreement-community-content-guidelines/";

export const EDITOR_SIDEBAR_WIDTH = 309;

export const GRADIENT_DEFAULTS = Object.freeze({
  directionDegrees: -64,
  startPercent: 16.45,
  endPercent: 83.55,
});

export const REQUIREMENT_TYPES = Object.freeze({
  date: "/chapter_requirement_types/MATCH_DATE",
  text: "/chapter_requirement_types/MATCH_TEXT",
  number: "/chapter_requirement_types/MATCH_NUMBER"
});
