import FormSubmit from '../formSubmit'
import { LOGIN, GOOGLE_LOGIN, FACEBOOK_LOGIN, APPLE_LOGIN } from '@/graphql/AuthMutations'
import { apolloClient } from '@/apollo/vue-apollo'
import i18n from '@/i18n'
import handleSuccessfulAuth from './handleSuccessfulAuth'
import router from '@/router'
import { socialTokenType } from './registration'

const loginParameterGroups = Object.freeze({
  Email: {
    mutation: LOGIN,
    mutationName: 'loginUser',
  },
  Google: {
    mutation: GOOGLE_LOGIN,
    tokenType: socialTokenType.GOOGLE,
    mutationName: 'googleLoginUser',
  },
  Facebook: {
    mutation: FACEBOOK_LOGIN,
    tokenType: socialTokenType.FACEBOOK,
    mutationName: 'facebookLoginUser',
  },
  Apple: {
    mutation: APPLE_LOGIN,
    tokenType: socialTokenType.APPLE,
    mutationName: 'appleLoginUser',
  },
});

export default {
  namespaced: true,

  state: () => ({
    ...FormSubmit.state
  }),
  mutations: {
    ...FormSubmit.mutations
  },
  getters: {
    ...FormSubmit.getters
  },
  actions: {
    logIn({ commit }, credentials) {
      commit('start');
      let loginParameters, variables, socialToken;
      if (credentials.username && credentials.password) {
        variables = {
          email: credentials.username,
          password: credentials.password,
        }
        loginParameters = loginParameterGroups.Email 
      } else if (credentials.googleToken) {
        variables = {
          googleToken: credentials.googleToken,
        }
        socialToken = credentials.googleToken;
        loginParameters = loginParameterGroups.Google
      } else if (credentials.facebookToken) {
        variables = {
          facebookToken: credentials.facebookToken,
        }
        socialToken = credentials.facebookToken;
        loginParameters = loginParameterGroups.Facebook
      } else if (credentials.appleToken) {
        variables = {
          appleToken: credentials.appleToken,
        }
        socialToken = credentials.appleToken;
        loginParameters = loginParameterGroups.Apple
      }

      apolloClient.mutate({
        mutation: loginParameters.mutation,
        variables: variables,
      }).then((response) => {
        let user =  response.data[loginParameters.mutationName].user

        // no user, send them off to register
        if (user == null) {
          // go to registration page
          router.push({ name: 'register-profile'}).catch(()=>{})
          commit('auth/registration/setSocialToken', socialToken, { root: true })
          commit('auth/registration/setSocialTokenType', loginParameters.tokenType, { root: true })
          commit('succeeded')
          return;
        }
        handleSuccessfulAuth(commit, user, credentials.redirect)
        commit('succeeded')
      }).catch((error) => {
        commit('failed')
        if (error.graphQLErrors) {
          for (const graphQlError of error.graphQLErrors) {
            if (graphQlError.name == "IncorrectEmailPasswordError") {
              commit('addErrorMessage', i18n.t('login.incorrectUsernameOrPassword') )
            }
          }
        }
      })
    },
  },
}