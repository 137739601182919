<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-3">
      <h2 class="dialog__subtitle">
        {{ $t("story.create.selectStoryTemplate") }}
      </h2>
      <v-tooltip
        content-class="story-city-tooltip"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <img
            v-bind="attrs"
            v-on="on"
            :src="require('@/assets/icons/question_mark.svg')"
          />
        </template>
        <span>{{ $t("story.create.selectTemplateTooltip") }}</span>
      </v-tooltip>
    </div>
    <v-card class="templates-container pr-1" elevation="0">
      <div class="scroll-container pa-2">
        <v-list class="template-list d-flex flex-column" subheader two-line>
          <v-list-item
            class="template-list-item"
            :class="{
              'selected-list-item':
                selectedTemplate && template.id === selectedTemplate.id,
            }"
            v-for="template in templates"
            :key="template.id"
            @click="selectTemplate(template)"
          >
            <v-list-item-icon class="template-icon-container">
              <!-- Template Icon -->
              <v-icon size="24" class="template-icon" color="#636262">
                {{ template.templateIcon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pa-0">
              <!-- Template Name -->
              <v-list-item-title class="template-list-item-title pa-0">
                {{ template.name }}
              </v-list-item-title>
              <!-- Template Description -->
              <v-list-item-subtitle class="template-list-item-subtitle ">
                {{ template.templateDescription }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="ma-0 mr-5">
              <!-- Template Preview Link -->
              <a
                v-if="template.templatePreviewLink"
                class="preview-link"
                :href="template.templatePreviewLink"
                target="_blank"
                >
                  {{ $t("common.preview") }}
                </a>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </div>
</template>

<script>
import gql from "graphql-tag";

const GET_TEMPLATES = gql`
  query {
    stories(isTemplate: true) {
      edges {
        node {
          id
          name
          templateIcon
          templateDescription
          templatePreviewLink
        }
      }
    }
  }
`;

export default {
  apollo: {
    templates: {
      query: GET_TEMPLATES,
      update(data) {
        const templates = data?.stories?.edges?.map((edge) => edge.node);
        // add a blank template to the beginning of the list
        templates.unshift({
          id: null,
          name: this.$t("story.create.blank"),
          templateIcon: "mdi-file-outline",
          templateDescription: this.$t("story.create.blankTemplateDescription"),
          templatePreviewLink: null,
        });
        return templates;
      },
    },
  },
  data() {
    return {
      selectedTemplate: null,
      showTemplateList: false,
    };
  },
  computed: {
    templateIconColor() {
      return "primary";
    },
  },
  methods: {
    selectTemplate(template) {
      this.selectedTemplate = template;
      this.$emit("template-selected", template.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card.templates-container {
  border-radius: 10px;
  border: 1px solid var(--v-lightBackground-base);
  background: var(--v-inputBackground-base);
}

.scroll-container {
  max-height: 250px;
}

.scroll-container::-webkit-scrollbar-track {
  margin: 8px 0px;
}

.template-list {
  gap: 8px;
}

.v-list-item.template-list-item {
  border-radius: 10px;
  border: 1px solid var(--v-lightBackground-base);
  padding: 10px;
  min-height: 0px;
}

.v-list-item .v-list-item__icon.template-icon-container {
  width: 34px;
  height: 34px;
  background-color: var(--v-lightBackground-base);
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  padding: 5px;
}

.template-icon {
  width: 100%;
  height: 100%;
}

.select-template-text {
  cursor: pointer;
  font-size: 24px;
}

.template-list-item-title,
.template-list-item-subtitle {
  white-space: normal;
  color: var(--v-textOnAccent-base);
  line-height: normal;
  letter-spacing: $font-spacing;
}

.template-list-item-title {
  font-size: 16px;
  font-weight: 600;
}

.template-list-item-subtitle {
  font-size: 14px;
  font-weight: 500;
}

.template-list .v-list-item.selected-list-item {
  background-color: #20A5F50D;
  border-color: var(--v-primary-base);
}

.preview-link {
  display: none;
}

.template-list-item:hover .preview-link {
  display: block;
  text-decoration: underline;
}
</style>
