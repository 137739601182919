export default {
  namespaced: true,
  state: {
    showUnsavedChangesModal: false,
    next: null,
    saveChanges: false,
  },
  getters: {
    getShowUnsavedChangesModal: (state) => state.showUnsavedChangesModal,
    getNext: (state) => state.next,
    getSaveChanges: (state) => state.saveChanges,
  },
  mutations: {
    setShowUnsavedChangesModal: (state, value) => {
      // eslint-disable-next-line no-param-reassign
      state.showUnsavedChangesModal = value
    },
    setNext: (state, value) => {
      state.next = value
    },
    setSaveChanges: (state, value) => {
      state.saveChanges = value
    },
  },
  actions: {
    showUnsavedChangesModal({commit}, next) {
      commit('setNext', next)
      commit('setShowUnsavedChangesModal', true)
    },
    modalSaveResponse({commit, getters}) {
      let next = getters.getNext
      commit('setShowUnsavedChangesModal', false)
      commit('setNext', null)
      commit('setSaveChanges', true)
      next(false)
    },
    modalLeaveResponse({commit, getters}) {
      commit('setShowUnsavedChangesModal', false)
      let next = getters.getNext
      next(true)
    },
  }
}
