import { AUTH_TOKEN, REFRESH_TOKEN } from '@/apollo/vue-apollo'
import router from '@/router'

export default function(vuexCommit, user, redirect) {
  localStorage.setItem(AUTH_TOKEN, user.token)
  localStorage.setItem(REFRESH_TOKEN, user.refreshToken)
  let me = user
  me.token = null
  me.refreshToken = null
  vuexCommit('auth/setMe', me, { root: true })
  let push = "/"
  if (redirect) {
    push = redirect

  }
  router.push(push).catch(()=>{})
}