import { render, staticRenderFns } from "./RoleBadge.vue?vue&type=template&id=80b9f01c&scoped=true"
import script from "./RoleBadge.vue?vue&type=script&lang=js"
export * from "./RoleBadge.vue?vue&type=script&lang=js"
import style0 from "./RoleBadge.vue?vue&type=style&index=0&id=80b9f01c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80b9f01c",
  null
  
)

export default component.exports