const palette = {
  blue: {
    desaturated: '#F3F9FF',
    extraLight: '#E9F6FE',
    lightFaded: '#62BFF7',
    light: '#4CB6F6',
    normal: '#1FA4F4',
    dark: '#1879B5',
    darker: '#0F4E75'
  },
  white: {
    normal: '#FFFFFF',
    faded: '#FAFAFA',
    greyed: '#FDFDFD',
    blueTint: '#FAFDFF',
  },
  grey: {
    lightest: '#FCFCFC',
    extraLight: '#F5F5F5',
    lighter: '#F4F4F4',
    light: '#EAEAEA',
    normalLight: '#D9D9D9',
    normal: '#BABABA',
    normalDark: '#959595',
    darkFaded: '#565050',
    dark: '#949191',
    darker: '#636262',
    extraDarkFaded: '#808080',
    darkest: '#2C2C2C',
  },
  purple: {
    faded: '#DCD8F3',
  },
  green: {
    lightFaded: '#e7f9f1',
    light: '#b8ecd6',
    normal: '#13BF77',
    dark: '#00A661',
  },
  red: {
    faded: '#FFD5D5',
    lighter: '#FFF5F5',
    light: '#FF5C5C',
    normal: "#E62828",
    normalAlt: '#FF3737',
    dark: '#DE5D24',
    darker: '#A61D1D',
  },
  orange: {
    faded: '#FFEBD8',
    light: '#F5792F',
    normal: '#EF7934',
    dark: '#E19820',
  },
  yellow: {
    normal: '#F5C907',
  }
}

export const light = {
  primary: palette.blue.normal,
  primaryLight: palette.blue.light,
  primaryFaded: palette.blue.lightFaded,
  primaryDark: palette.blue.dark,
  primaryDarker: palette.blue.darker,
  primaryExtraLight: palette.blue.extraLight,
  primaryAction: palette.blue.light,
  secondary: palette.orange.normal,
  secondaryLight: palette.orange.light,
  secondaryAction: palette.grey.darker,
  accent:  palette.blue.normal,
  secondaryAccentElement: palette.grey.normal,
  error: palette.red.normalAlt,
  errorBackground: palette.red.lighter,
  danger: palette.red.light, 
  background: palette.blue.desaturated,
  secondaryBackground: palette.white.normal,
  tertiaryBackground: palette.grey.normalLight,
  lightBackground: palette.grey.light, 
  backgroundExtraLight: palette.grey.lighter, 
  accentElement: palette.grey.dark,
  accentElementSecondary: palette.grey.extraLight,
  lightAccent: palette.grey.lightest,
  greenExtraLight: palette.green.lightFaded,
  greenLight: palette.green.light,
  green: palette.green.normal,
  greenDark: palette.green.dark,
  red: palette.red.normal,
  redDark: palette.red.dark,
  redDarker: palette.red.darker,
  orangeDark: palette.orange.dark,
  primaryText: palette.grey.extraDarkFaded,
  secondaryText: palette.grey.darkest,
  textOnAccent: palette.grey.darkFaded,
  textOnPrimary: palette.white.normal,
  textOnLightBackground: palette.grey.normalDark,
  demphasizedText: palette.grey.normal,
  chipPurple: palette.purple.faded,
  chipRed: palette.red.faded,
  chipOrange: palette.orange.faded,
  starFilled: palette.yellow.normal,
  starEmpty: palette.white.normal,
  inputBackground: palette.white.greyed,
  defaultOverlay: palette.white.faded,
  backgroundBlueTint: palette.white.blueTint,
  backgroundOverlayDarken: palette.grey.mediumDark,
}