import Arrow from '@/components/Icons/Arrow.vue';
import Pencil from '@/components/Icons/Pencil.vue';
import CheckboxOn from '@/components/Icons/CheckboxOn.vue';
import ChevronLeft from '@/components/Icons/ChevronLeft.vue';
import Chevronright from '@/components/Icons/ChevronRight.vue';
import ChevronDown from '@/components/Icons/ChevronDown.vue';
import Link from '@/components/Icons/Link.vue';
import Info from '@/components/Icons/Info.vue';
import Bold from '@/components/Icons/Bold.vue';
import BulletedList from '@/components/Icons/BulletedList.vue';
import H1 from '@/components/Icons/H1.vue';
import H2 from '@/components/Icons/H2.vue';
import H3 from '@/components/Icons/H3.vue';
import NumberedList from '@/components/Icons/NumberedList.vue';
import Strikethrough from '@/components/Icons/Strikethrough.vue';
import TextCenter from '@/components/Icons/TextCenter.vue';
import TextLeft from '@/components/Icons/TextLeft.vue';
import TextRight from '@/components/Icons/TextRight.vue';
import TextJustify from '@/components/Icons/TextJustify.vue';
import Underline from '@/components/Icons/Underline.vue';
import Page from '@/components/Icons/Page.vue';
import Upload from '@/components/Icons/Upload.vue';
import Plus from '@/components/Icons/Plus.vue';
import ZoomIn from '@/components/Icons/ZoomIn.vue';
import ZoomOut from '@/components/Icons/ZoomOut.vue';
import Close from '@/components/Icons/Close.vue';
import Trash from '@/components/Icons/Trash.vue';
import Title from '@/components/Icons/Title.vue';
import Genre from '@/components/Icons/Genre.vue';
import Rocket from '@/components/Icons/Rocket.vue';
import Help from '@/components/Icons/Help.vue';
import PublishPending from '@/components/Icons/PublishPending.vue';
import PublishSuccess from '@/components/Icons/PublishSuccess.vue';
import Warning from '@/components/Icons/Warning.vue';
import Duration from '@/components/Icons/Duration.vue';
import Tag from '@/components/Icons/Tag.vue';
import WarningAlt from '@/components/Icons/WarningAlt.vue';
import WarningRoundFilled from '@/components/Icons/WarningRoundFilled.vue';
import Check from '@/components/Icons/Check.vue';
import Remove from '@/components/Icons/Remove.vue';
import Search from '@/components/Icons/Search.vue';
import RadioMarked from '@/components/Icons/RadioMarked.vue';
import RadioBlank from '@/components/Icons/RadioBlank.vue';
import AgreementCheckboxChecked from '@/components/Icons/AgreementCheckboxChecked.vue';
import AgreementCheckboxUnchecked from '@/components/Icons/AgreementCheckboxBlank.vue';
import AgreementCheckboxAlert from '@/components/Icons/AgreementCheckboxAlert.vue';
import Age from '@/components/Icons/Age.vue';
import Redo from '@/components/Icons/Redo.vue';
import Undo from '@/components/Icons/Undo.vue';
import Video from '@/components/Icons/Video.vue';
import Image from '@/components/Icons/Image.vue';
import MusicNote from '@/components/Icons/MusicNote.vue';
import Fullscreen from '@/components/Icons/Fullscreen.vue';
import ImageResize from '@/components/Icons/ImageResize.vue';
import Add from '@/components/Icons/Add.vue';
import Save from '@/components/Icons/Save.vue';
import Facebook from '@/components/Icons/Facebook.vue';
import UniversalStory from '@/components/Icons/UniversalStory.vue'
import LocationPin from '@/components/Icons/LocationPin.vue';

export const icons = {
  values: {
    add: {
      component: Add,
    },
    arrow: {
      component: Arrow,
    },
    pencil: {
      component: Pencil,
    },
    checkboxOn: {
      component: CheckboxOn,
    },
    agreementCheckboxChecked: {
      component: AgreementCheckboxChecked,
    },
    agreementCheckboxUnchecked: {
      component: AgreementCheckboxUnchecked,
    },
    agreementCheckboxAlert: {
      component: AgreementCheckboxAlert,
    },
    chevronLeft: {
      component: ChevronLeft,
    },
    chevronRight: {
      component: Chevronright,
    },
    chevronDown: {
      component: ChevronDown,
    },
    link: {
      component: Link,
    },
    info: {
      component: Info,
    },
    bold: {
      component: Bold,
    },
    bulletedList: {
      component: BulletedList,
    },
    h1: {
      component: H1,
    },
    h2: {
      component: H2,
    },
    h3: {
      component: H3,
    },
    numberedList: {
      component: NumberedList,
    },
    strikethrough: {
      component: Strikethrough,
    },
    textCenter: {
      component: TextCenter,
    },
    textLeft: {
      component: TextLeft,
    },
    textRight: {
      component: TextRight,
    },
    textJustify: {
      component: TextJustify,
    },
    underline: {
      component: Underline,
    },
    page: {
      component: Page,
    },
    upload: {
      component: Upload,
    },
    plus: {
      component: Plus,
    },
    zoomIn: {
      component: ZoomIn,
    },
    zoomOut: {
      component: ZoomOut,
    },
    close: {
      component: Close,
    },
    trash: {
      component: Trash,
    },
    title: {
      component: Title,
    },
    genre: {
      component: Genre,
    },
    rocket: {
      component: Rocket,
    },
    help: {
      component: Help,
    },
    publishPending: {
      component: PublishPending,
    },
    publishSuccess: {
      component: PublishSuccess,
    },
    warning: {
      component: Warning,
    },
    warningRoundFilled: {
      component: WarningRoundFilled,
    },
    warningAlt: {
      component: WarningAlt,
    },
    duration: {
      component: Duration,
    },
    tag: {
      component: Tag,
    },
    check: {
      component: Check,
    },
    remove: {
      component: Remove,
    },
    search: {
      component: Search,
    },
    radioMarked: {
      component: RadioMarked,
    },
    radioBlank: {
      component: RadioBlank,
    },
    age: {
      component: Age,
    },
    redo: {
      component: Redo,
    },
    undo: {
      component: Undo,
    },
    video: {
      component: Video,
    },
    image: {
      component: Image,
    },
    musicNote: {
      component: MusicNote,
    },
    fullscreen: {
      component: Fullscreen,
    },
    imageResize: {
      component: ImageResize,
    },
    save: {
      component: Save,
    },
    facebook: {
      component: Facebook,
    },
    universalStory: {
      component: UniversalStory,
    },
    locationPin: {
      component: LocationPin,
    },
  },
}