import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '@/i18n'
import { apolloProvider } from '@/main'
import { USER } from "@/graphql/AuthMutations";
import { USER_ROLES } from '@/const'
import { AUTH_TOKEN } from '@/apollo/vue-apollo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true, hideSidebar: true },
    component: Home
  },
  {
    path: '/story/:id/:name?',
    name: 'story',
    meta: { requiresAuth: false, hideSidebar: true },
    component: () => import(/* webpackChunkName: "eula" */ '@/components/Story/Story.vue'),
  },
  {
    path: '/creator-dashboard/',
    name: 'creator-dashboard',
    meta: { requiresAuth: true, requiresCreator: true, title: i18n.t('storyHub.creatorDashboard') },
    components: {
      default: () => import(/* webpackChunkName: "creator-dashboard" */ '@/components/CreatorTools/CreatorDashboard.vue'),
      sidebar: () => import(/* webpackChunkName: "story-hub" */ '@/components/Sidebar/CreatorToolsSidebar.vue'),
    }
  },
  {
    path: '/creator-resources',
    name: 'creator-resources',
    meta: { title: i18n.t('creator.creatorResorces') },
    components: {
      default: () => import(/* webpackChunkName: "chapter-list" */ '@/components/CreatorTools/CreatorResources.vue'),
      sidebar: () => import(/* webpackChunkName: "story-hub" */ '@/components/Sidebar/CreatorToolsSidebar.vue'),
    }
  },
  {
    path: '/eula/',
    name: 'eula',
    meta: { title: i18n.t('login.youAgree0') },
    component: () => import(/* webpackChunkName: "eula" */ '@/components/Eula.vue'),
  },
  {
    path: '/forward-to-store/',
    name: 'forward',
    meta: { title: i18n.t('store.forwardToStore') },
    component: () => import(/* webpackChunkName: "eula" */ '@/components/ForwardToMobileStore.vue'),
  },
  {
    path: '/banking/',
    name: 'banking',
    component: () => import(/* webpackChunkName: "profile" */ '@/components/BecomeACreator/BankingInfo.vue'),
    meta: { requiresAuth: true, hideSidebar: true },
  },
  {
    path: '/become-creator/',
    name: 'become-creator',
    component: () => import(/* webpackChunkName: "eula" */ '@/components/BecomeACreator/Start.vue'),
    meta: { requiresAuth: true, hideSidebar: true },
  },
  {
    path: '/become-creator/wizard',
    name: 'become-creator-wizard',
    component: () => import(/* webpackChunkName: "becomeCreator" */ '@/components/BecomeACreator/Wizard/Container.vue'),
    meta: { requiresAuth: true, hideSidebar: true },
    children: [
      {
        path: 'account-setup',
        name: 'become-creator-account-setup',
        alias: ['/'],
        meta: { 
          title: i18n.t('becomeACreator.title') + " | " + i18n.t('becomeACreator.nameAndPenName'),
          header: i18n.t('becomeACreator.nameAndPenName'),
          step: 1
        },
        component: () => import(/* webpackChunkName: "becomeCreator" */ '@/components/BecomeACreator/Wizard/AccountSetup.vue'),
      },
      {
        path: 'update-username',
        name: 'become-creator-update-username',
        meta: { 
          title: i18n.t('becomeACreator.title') + " | " + i18n.t('becomeACreator.adjustYourUsername'),
          header: i18n.t('becomeACreator.adjustYourUsername'),
          step: 2
        },
        component: () => import(/* webpackChunkName: "becomeCreator" */ '@/components/BecomeACreator/Wizard/UpdateUsername.vue'),
      },
      {
        path: 'eula',
        name: 'become-creator-eula',
        meta: { 
          title: i18n.t('becomeACreator.title') + " | " + i18n.t('becomeACreator.creatorTermsAndConditions'),
          header: i18n.t('becomeACreator.creatorTermsAndConditions'),
          step: 3
        },
        component: () => import(/* webpackChunkName: "becomeCreator" */ '@/components/BecomeACreator/Wizard/AcceptEula.vue'),
      },
      {
        path: 'verify',
        name: 'become-creator-verify',
        meta: { 
          title: i18n.t('becomeACreator.title') + " | " + i18n.t('becomeACreator.verifyCreatorSignup'),
          header: i18n.t('becomeACreator.verifyCreatorSignup'),
          step: 4
        },
        component: () => import(/* webpackChunkName: "becomeCreator" */ '@/components/BecomeACreator/Wizard/VerifyEmail.vue'),
      },
    ]
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    meta: { 
      title: i18n.t('register.verification.verifyYourEmail'),
      header: i18n.t('becomeACreator.verifyCreatorSignup'),
    },
    component: () => import(/* webpackChunkName: "verify" */ '@/views/VerifyEmail.vue'),
  },
  {
    path: '/user-settings',
    name: 'user-settings',
    meta: { 
      title: i18n.t('becomeACreator.lastSteps'),
      requiresAuth: true,
    },
    redirect: {
      name: 'user-settings-account-info'

    },
    components: {
    default: () => import(/* webpackChunkName: "userSettings" */ '@/views/UserSettings/UserSettings.vue'),
    sidebar: () => import(/* webpackChunkName: "userSettings" */ '@/views/UserSettings/Sidebar.vue'),
    },
    children: [
      {
        path: 'account-info',
        name: 'user-settings-account-info',
        alias: '',
        component: () => import(/* webpackChunkName: "userSettings" */ '@/views/UserSettings/AccountInfo.vue'),
      },
    ],
  },
  {
    path: '/become-creator/next-steps',
    name: 'become-creator-next-steps',
    meta: { 
      title: i18n.t('becomeACreator.title') + " | " + i18n.t('becomeACreator.lastSteps'),
      header: i18n.t('becomeACreator.lastSteps'),
      requiresAuth: true,
      hideSidebar: true,
    },
    component: () => import(/* webpackChunkName: "becomeCreator" */ '@/components/BecomeACreator/HouseKeeping.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { requiresAdmin: true, title: i18n.t('admin.admin') },
    components: {
      default: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Admin.vue'),
      sidebar: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Sidebar.vue'),
    },
    children: [
      {
        path: 'users',
        name: 'admin-users',
        meta: { title: i18n.t('admin.admin') + " | " + i18n.t('admin.users.users') },
        alias: ['/'],
        component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Users/Users.vue'),
      },
      {
        path: 'stories',
        name: 'admin-stories',
        meta: { title: i18n.t('admin.admin') + " | " + i18n.t('admin.stories.stories') },
        component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Stories/Stories.vue'),
      },
      {
        path: 'review-stories',
        name: 'admin-review-stories',
        meta: { title: i18n.t('admin.admin') + " | " + i18n.t('admin.stories.stories') },
        component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Stories/Stories.vue'),
      },
      {
        path: 'story-purchases',
        name: 'admin-story-purchases',
        meta: { title: i18n.t('admin.admin') + " | " + i18n.t('admin.storyPurchases.storyPurchases') },
        component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/StoryPurchases/StoryPurchases.vue'),
      }
    ],
  },
  {
    path: '/storyhub/:id',
    name: 'story-hub',
    meta: { requiresAuth: true, requiresCreator: true, title: i18n.t('storyHub.storyHub') },
    components: {
      default: () => import(/* webpackChunkName: "story-hub" */ '@/components/CreatorTools/StoryHub/StoryHub.vue'),
      sidebar: () => import(/* webpackChunkName: "story-hub" */ '@/components/Sidebar/CreatorToolsStorySidebar.vue'),
    },
    children: [
      {
        path: 'basic-info',
        name: 'basic-info',
        meta: { title: i18n.t('storyHub.basicInfo.basicInfo') },
        alias: ['/'],
        component: () => import(/* webpackChunkName: "basic-info" */ '@/components/CreatorTools/StoryHub/BasicInfo/BasicInfoTab.vue'),
      },
      {
        path: 'chapter-list',
        name: 'chapter-list',
        meta: { title: i18n.t('storyHub.chapterList.chapterList') },
        component: () => import(/* webpackChunkName: "chapter-list" */ '@/components/CreatorTools/StoryHub/ChapterList/ChapterList.vue'),
      },
      {
        path: 'publishing',
        name: 'publishing',
        meta: { title: i18n.t('storyHub.publishing.publishing') },
        component: () => import(/* webpackChunkName: "publishing" */ '@/components/CreatorTools/StoryHub/Publishing/Publishing.vue'),
      },
    ],
  },
  {
    path: '/purchase/:id',
    alias: '/purchase/stories/:id',
    name: 'purchase',
    meta: { requiresAuth: true, hideSidebar: true },
    component: () => import(/* webpackChunkName: "purchase" */ '@/views/PurchaseStory/PurchaseStory.vue'),
  },
  {
    path: '/purchase/return/:id',
    name: 'purchase-return',
    meta: { requiresAuth: true, hideSidebar: true },
    component: () => import(/* webpackChunkName: "purchase" */ '@/views/PurchaseStory/Return.vue'),
  },
  {
    path: '/auth/',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/components/Auth/Auth.vue'),
    meta: { hideSidebar: true },
    children: [
      {
        path: 'sign-in',
        name: "sign-in",
        alias: ['/'],
        meta: { title: i18n.t('login.logInVerb'), requiresLoggedOut: true },
        component: () => import(/* webpackChunkName: "sign-in" */ '@/components/Auth/SignIn.vue'),
      },
      {
        path: 'register/',
        meta: { title: i18n.t('register.register') },
        component: () => import(/* webpackChunkName: "register" */ '@/components/Auth/Register/RegisterFlow.vue'),
        children: [
          {
            path: '/',
            name: 'register',
            meta: { step: 1, title: i18n.t('register.accountSetup'), requiresLoggedOut: true },
            component: () => import(/* webpackChunkName: "register" */ '@/components/Auth/Register/EmailPassword.vue'),
          },
          {
            path: 'profile',
            name: 'register-profile',
            meta: { step: 2, title: i18n.t('register.username'), requiresLoggedOut: true },
            component: () => import(/* webpackChunkName: "register" */ '@/components/Auth/Register/UserProfile.vue'),
          },
          {
            path: 'eula',
            name: 'register-eula',
            meta: { step: 3, title: i18n.t('register.eula'), requiresLoggedOut: true },
            component: () => import(/* webpackChunkName: "register" */ '@/components/Auth/Register/Eula.vue'),
          },
          {
            path: 'verification',
            name: 'verification',
            meta: { step: 4, title: i18n.t('register.verify') },
            component: () => import(/* webpackChunkName: "register" */ '@/components/Auth/Register/Verification.vue'),
          },
        ],
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        meta: { title: i18n.t('password.forgotPassword') },
        component: () => import(/* webpackChunkName: "register" */ '@/components/Auth/ResetPassword/ResetPasswordRequest.vue'),
      },
      {
        path: 'reset-password-sent',
        name: 'reset-password-sent',
        meta: { title: i18n.t('password.forgotPassword') },
        component: () => import(/* webpackChunkName: "register" */ '@/components/Auth/ResetPassword/ResetPasswordRequestSent.vue'),
      },
    ],
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    meta: { title: i18n.t('resetPassword.resetPassword'), hideSidebar: true },
    component: () => import(/* webpackChunkName: "reset-password" */ '@/components/Auth/ResetPassword/ResetPassword.vue'),
    children: [
      {
        path: '/',
        name: 'reset-password-form',
        meta: { title: i18n.t('resetPassword.resetPassword') },
        component: () => import(/* webpackChunkName: "reset-password" */ '@/components/Auth/ResetPassword/ResetPasswordForm.vue'),
      },
      {
        path: 'confirmed',
        name: 'reset-password-confirmed',
        meta: { title: i18n.t('resetPassword.resetPassword') },
        component: () => import(/* webpackChunkName: "reset-password" */ '@/components/Auth/ResetPassword/ResetPasswordConfirmation.vue'),
      },

    ]
  },
  {
    path: "/redirect/:option?",
    name: "redirect",
    meta: { requiresAuth: false, hideSidebar: true },
    component: () => import ('@/views/MobileRedirect'),
  },
  {
    path: "*",
    meta: { requiresAuth: false, hideSidebar: true },
    component: () => import('@/views/PageNotFound')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

async function currentUserHasRole(role) {
  try {
    const { data } = await apolloProvider.defaultClient.query(
      {
        query: USER,
      });
      
    if (data?.getLoggedInUser?.roles?.includes(role)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {

  // check if there is a /?token=token and set it in the store
  if (to.query.token) {
    localStorage.setItem(AUTH_TOKEN, to.query.token)
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.getItem(AUTH_TOKEN)) {
      return next()
    }
    else {
      return next({
        path: '/auth/sign-in',
        query: { redirect: window.location.pathname }
      })
    }
  }
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (await currentUserHasRole(USER_ROLES.Admin)) {
      return next()
    }
    else {
      return next({
        path: '/',
      });
    }
  }

  if (to.matched.some(record => record.meta.requiresCreator)) {
    if (await currentUserHasRole(USER_ROLES.Creator)) {
      return next()
    }
    else {
      return next({
        path: '/',
      })
    }
  }

  if (to.matched.some(record => record.meta.requiresLoggedOut)) {
    if (localStorage.getItem(AUTH_TOKEN)) {
      return next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    }
    else {
      return next() 
    }
  } 
  return next()
})

const DEFAULT_TITLE = 'Story City';
router.afterEach((to) => {
    // eslint-disable-next-line
    gtag('config', window.GA_TRACKING_ID, {
    page_path: to.fullPath,
    app_name: 'Story City App',
    send_page_view: true,
  });

  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = to.meta.title + " | " + DEFAULT_TITLE;
    }
    else {
      document.title = DEFAULT_TITLE;
    }
  });
});

export default router
