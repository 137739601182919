<template>
  <div>
    <BasicInfoLabel
      :imageLocation="require('@/assets/icons/page.svg')"
      :showWarning="showWarning"
    >
      {{ $t("story.tagline") }}
    </BasicInfoLabel>
    <v-textarea
      v-model="summary"
      v-bind="$attrs"
      id="taglineTextArea"
      class="story-city-textarea pa-0 mb-0"
      :label="$t('story.basicInfoForm.addYourTagline')"
      single-line
      auto-grow
      :rules="[
        () =>
          summary == null ||
          summary.length <= TAGLINE_TEXT_MAX_LENGTH ||
          $t('forms.maxLimitCharacters', {
            limit: TAGLINE_TEXT_MAX_LENGTH,
          }),
      ]"
      hide-details="auto"
      solo
      outlined
      flat
      :disabled="disabled"
    />
  </div>
</template>
<script>
import BasicInfoLabel from "./BasicInfoLabel.vue";

const TAGLINE_TEXT_MAX_LENGTH = 160;
export default {
  props: {
    value: String,
    disabled: Boolean,
    showWarning: {
      type: Boolean,
      default: false,
    },
    hideLabel: { type: Boolean, default: false },
  },
  data() {
    return {
      summary: this.value,
    };
  },
  watch: {
    summary(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      if (this.summary != newValue) {
        this.summary = newValue;
      }
    },
  },
  computed: {
    TAGLINE_TEXT_MAX_LENGTH() {
      return TAGLINE_TEXT_MAX_LENGTH;
    },
  },
  components: { BasicInfoLabel },
};
</script>

<style lang="scss" scoped>
.story-city-text-input {
  min-height: 56px;
}
</style>
