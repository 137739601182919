<template>
  <RoleBadge :user="user" :size="size">
    <Avatar :userProfile="user.userProfile" :size="size" />
  </RoleBadge>
</template>
<script>
import Avatar from '@/components/User/Avatar';
import RoleBadge from '@/components/User/RoleBadge';
export default {
  components: {
    Avatar,
    RoleBadge,
  },
  props: {
    user: Object,
    size: {
      type: Number,
      default: 64,
    },
  },
}
</script>