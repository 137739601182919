import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";
import story from './modules/story';
import auth from './modules/auth/auth';
import sharedStoryDescription from './modules/sharedStoryDescription';
import formNavGuard from './modules/formNavGuard';
import serverError from './modules/serverError';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    story,
    sharedStoryDescription,
    auth,
    formNavGuard,
    serverError,
  },
  plugins: [createPersistedState({
    paths: [
      'auth.me',
    ]
  })],
});
