<template>
  <v-btn
    v-bind="$attrs"
    :disabled="disabled"
    class="story-city-button"
    :style="disabled ? '' : `background-image: ${gradientStyle};`"
    @click="$emit('click')"
    v-on="on"
  >
    <div :class="!disabled ? 'button-text ' : ''">
      <slot />
    </div>
  </v-btn>
</template>

<script>
import { GRADIENT_DEFAULTS } from '@/const';

export default {
  name: "StoryCityGradientButton",
  props: {
    color1: {
      type: String,
      default: "redDark",
    },
    color2: {
      type: String,
      default: "secondaryLight",
    },
    gradientPercent: {
      type: Number,
      default: GRADIENT_DEFAULTS.startPercent,
    },
    gradientDeg: {
      type: Number,
      default: GRADIENT_DEFAULTS.directionDegrees,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    on: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    gradientStyle() {
      return `linear-gradient(${this.gradientDeg}deg, var(--v-${this.color1}-base) ${this.gradientPercent/2}%, var(--v-${this.color2}-base) ${50 + (this.gradientPercent/2)}%, var(--v-${this.color1}-base) ${100 - this.gradientPercent}%)`;
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--light.v-btn:hover::before {
  opacity: 0;
}

.v-btn {
  background-position: left bottom;
  background-size: 200% auto;
  transition: 0.5s;
}

.v-btn:hover {
  background-position: right top;
}
</style>