<template>
    <v-snackbar
      multi-line
      color="error"
      :timeout="10000"
      class="pa-15 font-weight-bold"
      v-model="snackbar"
    >
      <v-container>
        <v-row class="" align-content="center" align-items="center">
          <v-col cols="auto" class="align-self-center">
            <v-img
              :src="require('@/assets/icons/server_warning_icon.svg')"
              class="pr-8"
              contain
              max-width="50"
              height="50"
            />
          </v-col>
          <v-col class="font-weight-black" style="font-size: 1.1rem">
            <v-container class="pt-4">
              <v-row v-if="errorLabel != ''">
                <v-col class="pa-0 text-uppercase">{{errorLabel}}:</v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  {{ errorMessage }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>
<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    errorLabel: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      snackbar: this.value,
    }
  },
  watch: {
    value (val) {
      this.snackbar = val
    },
    snackbar (val) {
      this.$emit('input', val)
    },
  },
}
</script>