<template>
  <v-avatar :class="'avatar ma-0'+(!hasBorder ? ' no-border' : '')" :size="size">
    <img
      v-if="userProfile && userProfile.image"
      :key="userProfile.id"
      :src="userProfile.image.contentUrl"
      style="object-fit: cover;"
    />
    <v-icon
      v-else
      :size="size"
      color="primary"
      dark
    >
      mdi-account-circle
    </v-icon>
  </v-avatar>
</template>
<script>
export default {
  props: {
    userProfile: Object,
    size: {
      type: Number,
      default: 50,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
  },
};
</script>