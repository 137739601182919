<template>
  <label class="story-city-input-label d-flex align-center">
      <img
        v-if="imageLocation"
        class="story-city-label-icon"
        :src="imageLocation"
        alt=""
      />
      <v-icon class="mr-2" color="primary" v-else-if="iconName">{{ iconName }}</v-icon>
      <div><slot /></div>
      <v-icon class="ml-4" size="20" v-if="showWarning">$warning</v-icon>
  </label>
</template>
<script>
export default {
  props: {
    name: String,
    imageLocation: String,
    iconName: String,
    showWarning: {
      type: Boolean,
      default: false,
    },
  },
}
</script>