import { apolloClient } from '@/apollo/vue-apollo'
import StoryMutations from '@/graphql/StoryMutations'
import formSubmit from '@/store/modules/formSubmit'

export default {
  namespaced: true,
  state: {
    ...formSubmit.state,
    story: {},
    editedStory: {},
  },
  getters: {
    ...formSubmit.getters,
    getStory: (state) => {
     return  state.story} ,
    getEditedStory: (state) => state.editedStory,
    getStoryDescription: (state) => {
      return state.story.description
    }
  },
  mutations: {
    ...formSubmit.mutations,
    setStory(state, payload) { 
      state.story = payload 
    },
    setEditedStory(state, payload) { 
      state.editedStory = payload 
    }
  },
  actions: {
    async updateStory({ commit }, payload) {
      try {
        commit('start')

        let data = {
            id: payload.id,
            name: payload.name,
            summary: payload.summary,
            description: payload.description,
            duration: payload.duration,
            isTemplate: payload.isTemplate,
            isUniversal: payload.isUniversal,
            templateDescription: payload.templateDescription,
            templateIcon: payload.templateIcon,
            templatePreviewLink: payload.templatePreviewLink,
        }

        if (data.isTemplate == null) {
          delete data.isTemplate
          delete data.templateDescription
          delete data.templateIcon
          delete data.templatePreviewLink
        }

        data.tags = payload.tags.map( tag => { return tag.id } )

        data.ageRating = payload.ageRating?.id
        data.genre = payload.genre?.id

        const response = await apolloClient.mutate({
          mutation: StoryMutations.updateStory,
          variables: data,
        })
        commit('succeeded')
        commit('setStory', response.data.story)
        commit('setEditedStory', response.data.story)
      } catch (err) {
        commit('failed')
      }
    }
  }
}
